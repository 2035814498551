import React from 'react';

const Services = () => (
  <section className="services-section p-5 text-center">
    <h2>Our Services</h2>
    <ul>
      <li>B2B, B2C, ERP systems, and customized applications</li>
      <li>Web and mobile app development</li>
      <li>AI-based chatbots and omnichannel CRM solutions</li>
      <li>Business email and social media management</li>
      <li>24/7 customer service with lifetime support</li>
    </ul>
  </section>
);

export default Services;