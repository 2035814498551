import React from 'react';

const Footer = () => (
  <footer className="text-center p-3">
    <p>Contact us at: <a href="mailto:info@technoration.com">info@technoration.com</a></p>
    <p>Phone: +8801722696769</p>
    <p>Address: House No. 08/12 (1st Floor), Road No.4, Mirpur 1216, Dhaka, Bangladesh</p>
  </footer>
);

export default Footer;