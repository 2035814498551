import React from 'react';
import logo from '../resources/technoration-logo.png'; // Import your logo here

const Header = () => (
  <header className="text-center p-4">
    <img src={logo} alt="Technoration Logo" width="150" />
    <h1>Technoration Limited</h1>
  </header>
);

export default Header;